import {
  PAGINATION_COUNT,
  PAGINATION_COUNT_10,
  PAGINATION_START,
  PAGINATION_START_0,
} from "@recare/core/consts";
import { getUnixTime } from "date-fns";
import {
  USER_FILTER_DISTANCE_FROM_PROVIDER,
  USER_FILTER_START_DATE,
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
} from "../consts";
import { ProviderSearchFilters } from "./types";

export const DEFAULT_PROVIDER_SEARCH_FILTER_DISTANCE = 30;

export const initialStateProviderSearch: ProviderSearchFilters = {
  [USER_FILTER_DISTANCE_FROM_PROVIDER]: DEFAULT_PROVIDER_SEARCH_FILTER_DISTANCE,
  [USER_FILTER_START_DATE]: getUnixTime(new Date()),
  [PAGINATION_START]: PAGINATION_START_0,
  [PAGINATION_COUNT]: PAGINATION_COUNT_10,
  [USER_FILTER_WITH_CONSULTANTS]: false,
  [USER_FILTER_WITH_PROVIDERS]: false,
};
