import { Session } from "@recare/core/model/session";
import useTrackingProvider from "@recare/core/model/tracker/useTrackingProvider";
import { AppType } from "@recare/core/types";
import { PlatformInfo } from "@recare/core/validationSchemas/tracking";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectLoggedInAccountId } from "reduxentities/selectors";

export function TrackingProvider({
  app,
  children,
  platformInfo,
}: {
  app: AppType;
  children: ReactNode;
  platformInfo: PlatformInfo;
}) {
  const account_id = useSelector(selectLoggedInAccountId);
  const session = new Session();

  const { Track } = useTrackingProvider({
    account_id,
    app,
    platformInfo,
    session,
  });

  return <Track>{children}</Track>;
}

export default TrackingProvider;
