import { RequestPage } from "../types";
import { createMock, MockFn } from "./index";

const mockRequestsPayload: MockFn<Partial<RequestPage>> = (args) =>
  createMock("RequestsPayload", args, () => ({
    requests: args?.requests ?? [],
    requests_light: args?.requests_light ?? [],
    redacted_count: args?.redacted_count ?? null,
    ids: args?.ids ?? [],
    total: args?.total ?? 0,
    remaining: args?.remaining ?? 0,
    hash: args?.hash ?? "hash",
  }));

export default mockRequestsPayload;
