import {
  Theme,
  ThemeProvider as MuiThemeProviderV5,
  ThemeProvider,
} from "@mui/material/styles";
import { AppType } from "@recare/core/types";
import { getTheme } from "ds/materials/theme";
import { ThemeProvider as Emotion10ThemeProvider } from "emotion-theming";
import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default function RecareThemeProvider({
  app,
  children,
}: {
  app: AppType;
  children: React.ReactNode;
}) {
  return (
    <Emotion10ThemeProvider theme={getTheme(app)}>
      <StyledThemeProvider theme={getTheme(app)}>
        <MuiThemeProviderV5 theme={getTheme(app)}>
          <ThemeProvider theme={getTheme(app)}>{children}</ThemeProvider>
        </MuiThemeProviderV5>
      </StyledThemeProvider>
    </Emotion10ThemeProvider>
  );
}
