import { Account, DeepNullableObj } from "@recare/core/types";
import { defaultsDeep } from "lodash";
import { MockFn, createMock } from "./index";
import mockEvent from "./mockEvent";

const mockAccount: MockFn<Partial<DeepNullableObj<Account>>> = (args) =>
  defaultsDeep(
    args,
    createMock(
      "Account",
      args,
      (shallow): DeepNullableObj<Account> => ({
        __typename: "Account",
        id: 1,
        academic_title: null,
        account_type: null,
        activities: {
          add_members_cta: { updated_at: null },
        },
        created_at: 1420066800,
        email_requests: null,
        email_summary: null,
        email: "jorg.lowenstein@recaresolutions.com",
        events: mockEvent({ ...shallow("Event") }),
        experiments: null,
        fax_number: null,
        first_login: null,
        first_name: "Jörg",
        gender: null,
        intercom_id: null,
        last_login: null,
        last_name: "Löwenstein",
        mobile_phone: null,
        notes: null,
        phone: null,
        position: null,
        public_key: null,
        ratings: [],
        receive_sms: null,
        roles: {
          admin_roles: null,
          careprovider_roles: [],
          careseeker_roles: [],
        },
        salutation: null,
        seald_database_key: "key",
        seald_registered: true,
        seald_test_identity: "key",
        seald_two_man_rule_key: "key",
        seald_user_license_token: "token",
        status: null,
        title: null,
        browser_notifications_disabled: true,
      }),
    ),
  );

export default mockAccount;
